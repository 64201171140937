
import { computed, defineComponent, onMounted, onUpdated, ref } from "vue";
import { useI18n } from "vue-i18n";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import ApiService from "@/core/services/ApiService";
import EquipmentsTypeForm from "@/views/catalog/equipment_type/EquipmentsTypeForm.vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}
export default defineComponent({
  name: "EquipmentType",
  components: {
    TableBasicSelect,
    EquipmentsTypeForm,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const showDelete = ref(false);
    const deleteId = computed(() => store.getters.DeleteId);
    const header = ref([{ column: t("iname"), key: "name", size: "extra_large" }]);
    const isLoading = ref(true);
    const search = ref("");
    const tableButtons = ref([]);
    const dataList = ref([]);
    const dataObject = ref([] as any);
    const dialogVisible = ref(false);
    const itemID = ref("");
    const modalMode = ref("add");
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      last_page: 1,
      total: 1,
    });

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
          typeof event === "object" ? parseInt(event.target.value) : event;
      getItems();
    };
    const currentPageChange = (val) => {
      pagination.value.page = val;
      getItems();
    };

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "equipments type: create").length >
            0
        );
      }
      return false;
    });

    const canUpdate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "equipments type: update").length >
            0
        );
      }
      return false;
    });

    const canShow = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "equipments type: show").length >
            0
        );
      }
      return false;
    });

    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "equipments type: delete").length >
            0
        );
      }
      return false;
    });

    const forceDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "equipments type: force_delete").length >
            0
        );
      }
      return false;
    });

    const createElement = () => {
      dialogVisible.value = true;
      itemID.value = "";
      modalMode.value = "add";
    };

    const getItems = () => {
      store.commit("setLoadingStatus", true);
      ApiService.query("/api/equipment-types/lists", {
        params: {
          name: search.value,
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
        },
      }).then(({ data }) => {
        isLoading.value = false;
        const items: Record<string, any> = [];
        dataList.value = data.equipment_types;
        pagination.value.total = data.pagination.total;
        pagination.value.page = data.pagination.current_page;
        pagination.value.last_page = data.pagination.last_page;
        tableButtons.value = [
          {
            name: "Editar" as never,
            type: "EDIT" as never,
            color: "primary" as never,
          } as never,
        ];
        data.equipment_types.forEach((element) => {
          const td = {
            id: element.id,
            name: element.name,
            buttons: [{ type: "EDIT", id: element.id }],
          };
          items.push(td);
        });
        dataObject.value = items;
        store.commit("setLoadingStatus", false);
      });
    };

    const resetModal = () => {
      dialogVisible.value = false;
      getItems();
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(() => {
      console.clear();
      setCurrentPageBreadcrumbs("equipments_type", [
        { route: "/equipments-catalogs", label: "equipments" },
      ]);
      getItems();
    });

    const deleteDialog = (value) => {
      store.commit("setDeleteId", value);
      showDelete.value = true;
    };

    const deleteElement = () => {
      store.commit("setLoading", false);
      ApiService.delete(
        `/api/equipment-types/${deleteId.value}?force_delete=true`
      ).then(({ data }) => {
        store.commit("setDeleteId", undefined);
        showDelete.value = false;
        getItems();
      });
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 == "EDIT") {
        dialogVisible.value = true;
        itemID.value = param1;
        modalMode.value = "edit";
      }
      if (param2 == "REMOVE") {
        deleteDialog(param1);
      }
    };

    const onSearch = () => {
      dataObject.value = dataList.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
      if (!dataObject.value.length) {
        getItems();
      }
    };

    return {
      forceDelete,
      user,
      canCreate,
      canShow,
      canUpdate,
      canDelete,
      dataList,
      dataObject,
      dialogVisible,
      itemID,
      modalMode,
      header,
      tableButtons,
      isLoading,
      search,
      showDelete,
      deleteDialog,
      deleteElement,
      onSearch,
      actionsButton,
      createElement,
      resetModal,
      pagination,
      currentPageChange,
      setItemsPerPage,
    };
  },
});
